.apexcharts-tooltip-text {
    flex-grow: 1;
}

.apexcharts-tooltip-y-group {
    display: flex;
}

.apexcharts-tooltip-text-y-value {
    margin-left: auto !important;
}

.apexcharts-tooltip-text-y-label {
    margin-right: 8px;
}

.apexcharts-legend {
    gap: 0.5rem;
}