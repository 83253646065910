@keyframes toastAnimation {
  0% {
    bottom: 0;
  }

  3.3% {
    bottom: 5rem;
  }

  96.7% {
    bottom: 5rem;
  }

  100% {
    bottom: 0;
  }
}
