@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap');

#root {
  background-color: #e5e5e5;
}

* {
  font-family: 'Poppins', sans-serif;
}

.App {
  text-align: center;
  width: 28rem;
  margin: auto;
  background-color: #fff;
}

.footer {
  width: 28rem;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@media screen and (min-width: 448px) {
  .App {
    width: 28rem;
  }

  .footer {
    width: 28rem;
  }
}

@media screen and (max-width: 448px) and (min-width: 360px) {

  .App,
  .footer {
    width: 100vw;
  }
}

@media screen and (max-width: 360px) {

  .App,
  .footer {
    width: 360px;
  }
}

.grid {
  display: grid;
}

.flex {
  display: flex;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-column {
  flex-direction: column;
}


.flex-row {
  flex-direction: row;
}

.flex-wrap {
    flex-wrap: wrap;
}

.block {
  display: block;
}

.none {
  display: none;
}

.inline {
  display: inline;
}

.inline-block {
  display: inline-block;
}

.visibility-hidden {
  visibility: hidden;
}

.visibility-visible {
  visibility: visible;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.align-center {
  align-items: center;
}

.align-start {
  align-items: flex-start;
}

.align-end {
  align-items: flex-end;
}

.align-stretch {
  align-items: stretch;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.m-auto {
  margin: auto;
}

.m-0 {
  margin: 0;
}

.m-1 {
  margin: 4px;
}

.m-2 {
  margin: 8px;
}

.m-3 {
  margin: 12px;
}

.m-4 {
  margin: 16px;
}

.m-5 {
  margin: 20px;
}

.ml-0 {
  margin-left: 0;
}

.ml-1 {
  margin-left: 4px;
}

.ml-2 {
  margin-left: 8px;
}

.ml-3 {
  margin-left: 12px;
}

.ml-4 {
  margin-left: 16px;
}

.ml-5 {
  margin-left: 20px;
}

.ml-6 {
  margin-left: 24px;
}

.mr-0 {
  margin-right: 0;
}

.mr-1 {
  margin-right: 4px;
}

.mr-2 {
  margin-right: 8px;
}

.mr-3 {
  margin-right: 12px;
}

.mr-4 {
  margin-right: 16px;
}

.mr-5 {
  margin-right: 20px;
}

.mr-6 {
  margin-right: 24px;
}


.mh-0 {
  margin-left: 0;
  margin-right: 0;
}

.mh-1 {
  margin-left: 4px;
  margin-right: 4px;
}

.mh-2 {
  margin-left: 8px;
  margin-right: 8px;
}

.mh-3 {
  margin-left: 12px;
  margin-right: 12px;
}

.mh-4 {
  margin-left: 16px;
  margin-right: 16px;
}

.mh-5 {
  margin-left: 20px;
  margin-right: 20px;
}

.mh-6 {
  margin-left: 24px;
  margin-right: 24px;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: 4px;
}

.mb-2 {
  margin-bottom: 8px;
}

.mb-3 {
  margin-bottom: 12px;
}

.mb-4 {
  margin-bottom: 16px;
}

.mb-5 {
  margin-bottom: 20px;
}

.mb-6 {
  margin-bottom: 20px;
}

.mb-7 {
  margin-bottom: 28px;
}

.mb-8 {
  margin-bottom: 32px;
}

.mt-auto {
  margin-top: auto;
}

.mt-0 {
  margin-top: 0;
}

.mt-1 {
  margin-top: 4px;
}

.mt-2 {
  margin-top: 8px;
}

.mt-3 {
  margin-top: 12px;
}

.mt-4 {
  margin-top: 16px;
}

.mt-5 {
  margin-top: 20px;
}

.mt-6 {
  margin-top: 24px;
}

.mt-7 {
  margin-top: 32px;
}

.mt-8 {
  margin-top: 45px;
}

.mt-auto {
  margin-top: auto;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.p-auto {
  padding: auto;
}

.p-0 {
  padding: 0;
}

.p-1 {
  padding: 4px;
}

.p-2 {
  padding: 8px;
}

.p-3 {
  padding: 12px;
}

.p-4 {
  padding: 16px;
}

.p-5 {
  padding: 20px;
}

.p-6 {
  padding: 24px;
}

.pl-0 {
  padding-left: 0;
}

.pl-1 {
  padding-left: 4px;
}

.pl-2 {
  padding-left: 8px;
}

.pl-3 {
  padding-left: 12px;
}

.pl-4 {
  padding-left: 16px;
}

.pl-5 {
  padding-left: 20px;
}

.pl-7 {
  padding-left: 28px;
}

.pr-0 {
  padding-right: 0;
}

.pr-1 {
  padding-right: 4px;
}

.pr-2 {
  padding-right: 8px;
}

.pr-3 {
  padding-right: 12px;
}

.pr-4 {
  padding-right: 16px;
}

.pr-5 {
  padding-right: 20px;
}

.pr-7 {
  padding-right: 28px;
}

.pb-0 {
  padding-bottom: 0;
}

.pb-1 {
  padding-bottom: 4px;
}

.pb-2 {
  padding-bottom: 8px;
}

.pb-3 {
  padding-bottom: 12px;
}

.pb-4 {
  padding-bottom: 16px;
}

.pb-5 {
  padding-bottom: 20px;
}

.pb-6 {
  padding-bottom: 24px;
}

.pb-7 {
  padding-bottom: 28px;
}

.pb-8 {
  padding-bottom: 32px;
}

.pt-0 {
  padding-top: 0;
}

.pt-1 {
  padding-top: 4px;
}

.pt-2 {
  padding-top: 8px;
}

.pt-3 {
  padding-top: 12px;
}

.pt-4 {
  padding-top: 16px;
}

.pt-5 {
  padding-top: 20px;
}

.h-0 {
  height: 0;
}

.w-0 {
  width: 0;
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.w-auto {
  width: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-visible {
  overflow: visible;
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-auto {
  overflow: auto;
}

.overflow-ellipsis {
  text-overflow: ellipsis;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-x-visible {
  overflow-x: visible;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-y-visible {
  overflow-y: visible;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.overflo-y-auto {
  overflow-y: auto;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.fixed {
  position: fixed;
}

.sticky {
  position: sticky;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.float-none {
  float: none;
}

.border-radius {
  border-radius: 8px;
}

.border-grey {
  border: 1px solid #e5e5e5;
}

.border-dash-grey{
  border: 1px dashed;
}

.border-radius2 {
  border-radius: 16px;
}

.cursor-pointer {
  cursor: pointer;
}

.white-nowrap {
  white-space: nowrap;
}

.bs-1 {
  box-shadow: 0 0.0625rem 0.25rem rgba(0, 0, 0, 0.12);
}

.bs-2 {
  box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
}

.flex-grow {
  flex-grow: 1;
}

.border-pink {
  border: 0.0625rem solid #E91E63;
}

.flex-gap-1 {
  gap: 4px;
}

.flex-gap-2 {
  gap: 8px;
}

.flex-gap-3 {
  gap: 12px;
}

.flex-gap-4 {
  gap: 16px;
}

.MuiTooltip-tooltip {
  background: #333 !important;
  max-width: 12.5rem !important;
}

.slick-track, .slick-list, .slick-slider, .slick-initialized {
  z-index: 10;
}

video {
  border-radius: 0.75rem;
}

.left-0 {
  left: 0;
}

.slick-dots>.slick-active>div {
  opacity: 1 !important;
}

.lottie>svg {
  width: auto !important;
}

.list::marker {
  font-size: 0.5rem;
}

.marquee-text {
  background: linear-gradient(92deg, #FFD2A8 0%, rgba(255, 210, 168, 0.60) 100%);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.text-overline {
  text-decoration: overline;
}

.text-through {
  text-decoration: line-through;
}

.text-underline {
  text-decoration: underline;
}

.text-underline-overline {
  text-decoration: underline overline;
}

.white-gradient {
  background: linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.80) 100%);
}

.blue-gradient {
  background: linear-gradient(98deg, #17336F 4.1%, #254EA3 100%);
}

.clamp-text-lines-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  display: -webkit-box;
}

.clamp-text-lines-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
}

.clamp-text-lines-3 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  display: -webkit-box;
}

.modal {
  z-index: 201;
}

.story-root {
  background-image: url('https://d3g01po1nkka75.cloudfront.net/creator-master/AcquisitionBackground.svg');
  background-size: cover;
  background-repeat: no-repeat;
  overflow-x: hidden;
}

.story-button {
  width: 90%;
  z-index: 1001;
  margin: auto;
  position: relative;
}