.loading-chart svg path {
    stroke-dashoffset: 210;
    stroke-dasharray: 210;
    animation: draw 0.9s forwards infinite;
}

@keyframes draw {
    0% {
        stroke-dashoffset: 210;
        fill-opacity: 0;
    }

    50% {
        fill-opacity: 0.1;
    }

    100% {
        stroke-dashoffset: 0;
        fill-opacity: 1;
    }
}

@keyframes fill-in {
    to {
        fill-opacity: 1;
    }
}