.phone-input {
  width: auto;
  max-width: 5.5rem;
  min-height: max-content;
  object-fit: cover;
}

.phone-input .PhoneInputInput {
  object-fit: cover;
  min-height: 100%;
  height: 3.5rem;
  border-radius: 0.5rem;
  border: none;
  border-right: 1px solid #e5e5e5;
  border-radius: 0;
  outline: none;
  font-size: 1rem;
  text-align: left;
  padding: 0;
  padding-right: 5px;
  width: auto;
}

.phone-input .PhoneInputCountrySelect {
  width: 4.2rem;
}

.phone-input .PhoneInputCountrySelectArrow {
  display: none;
}
