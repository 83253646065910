.tile-animation {
  -webkit-transform: translateY(70px) rotate(-10deg) translateZ(0);
  transform: translateY(70px) rotate(-5deg) translateZ(0);
  -webkit-transition-delay: 0.3s;
  -o-transition-delay: 0.3s;
  transition-delay: 0.3s;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  opacity: 0;
}

.inView {
  opacity: 1;
  -webkit-transform: translateY(0px) rotate(0deg) translateZ(0);
  transform: translateY(0px) rotate(0deg) translateZ(0);
}

.right-transition {
  animation: rightTransition 0.15s forwards;
  animation-timing-function: ease-in-out;
  opacity: 0;
}

.left-transition {
  animation: leftTransition 0.15s forwards;
  animation-timing-function: ease-in-out;
  opacity: 0;
}

@keyframes rightTransition {
  0% {
    -webkit-transform: translateX(-50px) rotate(0) translateZ(0);
    transform: translateX(-50px) rotate(0) translateZ(0);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0) rotate(0) translateZ(0);
    transform: translateX(0) rotate(0) translateZ(0);
    opacity: 1;
  }
}

@keyframes leftTransition {
  0% {
    -webkit-transform: translateX(25px) rotate(0) translateZ(0);
    transform: translateX(25px) rotate(0) translateZ(0);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0) rotate(0) translateZ(0);
    transform: translateX(0) rotate(0) translateZ(0);
    opacity: 1;
  }
}

.fade-scale {
  animation: fadeScale 0.15s forwards;
  animation-timing-function: ease-in-out;
  opacity: 0;
}

@keyframes fadeScale {
  0% {
    -webkit-transform: scaleX(0.9);
    transform: scaleX(0.9);
    opacity: 0.8;
  }

  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    opacity: 1;
  }
}

.bubble-animation {
  -webkit-transform: scaleX(0.8);
  transform: scaleX(0.8);
  opacity: 0;
}

.bubble {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transition-delay: 0.3s;
  -o-transition-delay: 0.3s;
  transition-delay: 0.3s;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  opacity: 1;
}

.animate-width {
  animation: animateWidth 0.15s forwards;
  animation-timing-function: ease-in-out;
}

@keyframes animateWidth {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

.animate-width-left {
  animation: animateWidthLeft 0.3s forwards;
  animation-timing-function: ease-in-out;
}

@keyframes animateWidthLeft {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

.fade-appear {
  animation: fadeAppear 0.5s forwards;
  animation-delay: 0.25s;
  animation-timing-function: ease-in-out;
  opacity: 0;
}

.fade-appear-2 {
  animation: fadeAppear 0.5s forwards;
  animation-delay: 0.5s;
  animation-timing-function: ease-in-out;
  opacity: 0;
}

@keyframes fadeAppear {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.refer-card {
  animation: fadeInOut 12s infinite;
  opacity: 0;
}

.refer-card-2 {
  animation: fadeInOut 12s infinite;
  animation-delay: 4s;
  opacity: 0;
}

.refer-card-3 {
  animation: fadeInOut 12s infinite;
  animation-delay: 8s;
  opacity: 0;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }

  16.5% {
    opacity: 1;
  }

  33%, 100% {
    opacity: 0;
  }
}

.shadow-transition {
  animation: shadowTransition 1s infinite;
}

@keyframes shadowTransition {
  0% {
    box-shadow: none;
  }

  50% {
    box-shadow: 0rem 0rem 1rem #e91e63;
  }

  100% {
    box-shadow: 0rem 0rem 0.1rem #e91e63;
  }
}

.page-click-to-top-transition {
  animation: pageClickToTopTransition 0.25s ease-out forwards;
}

.page-click-to-left-transition {
  animation: pageClickToLeftTransition 0.15s ease-out forwards;
}

@keyframes pageClickToTopTransition {
  0% {
    top: 100vh;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }

  100% {
    top: 0;
    box-shadow: none;
  }
}

@keyframes pageClickToLeftTransition {
  0% {
    left: 100%;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }

  100% {
    left: 0;
    box-shadow: none;
  }
}
